@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --primary-color: #00AEEF;
  --secondary-color: #0077BE;
  --text-color: #333333;
  --background-color: #FFFFFF;
  --accent-color: #4D4D4D;
  --cols: 12;
  --rows: 12;
  --body-size: calc(100vw + (100 / var(--cols)));
  --dot-size: 1.75vw;
  --gutter: 3vw;
  --line-weight: 2px;
  --speed: 2.5s;
  --bg: white;
  --fg: #00AEEF;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1200' height='800' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3ClinearGradient id='bgGradient' x1='0%25' y1='0%25' x2='100%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color:%23FFFFFF;stop-opacity:1'/%3E%3Cstop offset='100%25' style='stop-color:%23E6F7FF;stop-opacity:1'/%3E%3C/linearGradient%3E%3ClinearGradient id='shapeGradient' x1='0%25' y1='0%25' x2='100%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color:%2300AEEF;stop-opacity:0.1'/%3E%3Cstop offset='100%25' style='stop-color:%230077BE;stop-opacity:0.1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='1200' height='800' fill='url(%23bgGradient)'/%3E%3Cg opacity='0.7'%3E%3Cpath d='M0 400 Q 300 350, 600 400 T 1200 400' fill='none' stroke='%2300AEEF' stroke-width='2'/%3E%3Cpath d='M0 420 Q 300 370, 600 420 T 1200 420' fill='none' stroke='%230077BE' stroke-width='2'/%3E%3C/g%3E%3Cg fill='url(%23shapeGradient)'%3E%3Cpolygon points='0,0 200,0 0,200'/%3E%3Cpolygon points='1200,800 1000,800 1200,600'/%3E%3Cpolygon points='600,0 800,0 700,200'/%3E%3Cpolygon points='400,800 600,800 500,600'/%3E%3C/g%3E%3Cg fill='%234D4D4D' opacity='0.05'%3E%3Ccircle cx='100' cy='100' r='50'/%3E%3Ccircle cx='1100' cy='700' r='70'/%3E%3Ccircle cx='300' cy='700' r='40'/%3E%3Ccircle cx='900' cy='100' r='60'/%3E%3C/g%3E%3C/svg%3E");
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: white;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo svg {
  height: 40px;
  width: auto;
}

nav a {
  color: #333333;
  text-decoration: none;
  margin-left: 1.5rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #00AEEF;
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 20px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 3.5rem;
  color: #00AEEF;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #333333;
}

.cta-button {
  background-color: #00AEEF;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button:hover {
  background-color: #0077BE;
}

.section {
  padding: 5rem 0;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--secondary-color);
}

.values {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.value-item {
  text-align: center;
  margin-bottom: 2rem;
  flex-basis: 30%;
}

.value-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member {
  text-align: center;
  margin-bottom: 2rem;
  flex-basis: 30%;
}

.member-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  overflow: hidden;
}

.member-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsletter {
  background-color: var(--primary-color);
  padding: 3rem 0;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.newsletter-form input {
  padding: 0.8rem;
  width: 300px;
  font-size: 1rem;
  border: none;
}

.newsletter-form button {
  background-color: var(--secondary-color);
  color: var(--background-color);
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #f39c12;
}

.footer {
  background-color: #34495e;
  padding: 2rem 0;
  text-align: center;
}

.social-icons {
  margin-top: 1rem;
}

.social-icon {
  color: white;
  font-size: 1.5rem;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .logo svg {
    height: 30px;
  }

  nav a {
    margin-left: 1rem;
    font-size: 0.9rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .value-item, .team-member {
    flex-basis: 100%;
  }

  .newsletter-form {
    flex-direction: column;
    align-items: center;
  }

  .newsletter-form input, .newsletter-form button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.contact-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.section-description {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

.submit-button {
  background-color: #00AEEF;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0077BE;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
}
.open-positions-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.positions-list {
  list-style-type: none;
  padding: 0;
}

.position-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.position-item h3 {
  color: #00AEEF;
  margin-bottom: 10px;
}

.position-item h4 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.position-item ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.apply-button {
  background-color: #00AEEF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #0077BE;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #00AEEF;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: #00AEEF;
  color: #fff;
}

.submit-button:hover {
  background-color: #0077BE;
}

.cancel-button {
  background-color: #f8f9fa;
  color: #333;
}

.cancel-button:hover {
  background-color: #e9ecef;
}
/* ... (previous styles remain the same) ... */

.status-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}